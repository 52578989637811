









































































import BeneficiaryGroup from '../../../../../../../store/models/BeneficiaryGroup'
import CreditLine from '../../../../../../../store/models/CreditLine'
import Reimbursement from '../../../../../../../store/models/Reimbursement'
import { Response } from '@vuex-orm/plugin-axios'

export default {
  data() {
    return {
      loading: false,
      beneficiaryGroups: [],
      selectedGroup: null,
      columns: [
        {
          label: 'N° transaction',
          field: 'transaction.reference'
        },
        {
          label: 'Payer pour',
          field: 'beneficiary.name'
        },
        {
          label: 'Payer par',
          field: 'payerPhone'
        },
        {
          label: 'Montant Remboursé',
          field: 'amount'
        },
        {
          label: 'Date',
          field: 'createdAt'
        },
        {
          label: 'Statut',
          field: 'state'
        }
        // {
        //   label: 'Actions',
        //   field: '_actions',
        //   align: 'left'
        // }
      ]
    }
  },

  computed: {
    creditLineId(this: any): string {
      return this.$route.params.creditLineId
    },

    creditLine(this: any) {
      return CreditLine.find(this.creditLineId)
    },

    // beneficiaryGroups() {
    //   return BeneficiaryGroup.query()
    //     .withAll()
    //     .get()
    // },

    reimbursements(this: any) {
      if (!this.selectedGroup) {
        return []
      }

      return Reimbursement.query()
        .where('beneficiaryGroupId', this.selectedGroup.id)
        .with('transaction')
        .with('beneficiary')
        .get()
    }
  },

  created(this: any) {
    BeneficiaryGroup.api()
      .fetchByCreditLine(this.creditLineId)
      .then((response: Response) => {
        if (response.response.status === 200) {
          this.beneficiaryGroups = response.response.data
          if (!this.selectedGroup && this.beneficiaryGroups.length > 0) {
            this.selectGroup(this.beneficiaryGroups[0])
          }
        }
      })
  },

  methods: {
    selectGroup(this: any, group: any) {
      this.selectedGroup = group
      Reimbursement.api().fetchByBeneficiaryGroup(group.id)
    }
  }
}
